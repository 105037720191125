import classNames from 'classnames';
import { graphql } from 'gatsby';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  CenteredContainer,
  ContactForm,
  NarrowCenteredContainer,
  SectionHeader,
  WorkProcessImgTile,
  WorkProcessTextTile,
} from '../components';
import {
  tile1Img,
  tile2Img,
  bgGraingImg,
  bgBannerLarge,
  logoIcon,
  whoWeAreImg,
  quoteImg,
  quoteIcon,
  logoCircleImg,
} from '../images';
import { first, last } from 'lodash-es';

const OurCompanySubSectionHeader = ({
  children,
  isActive,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & { isActive: boolean }) => {
  return (
    <span
      className={classNames(
        'block font-secondary font-black text-5xl text-header mb-4',
        !isActive && 'opacity-50'
      )}
    >
      {children}
    </span>
  );
};

const OurCompanyTile = ({
  id,
  children,
  visibleOnScreen,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { visibleOnScreen: boolean }) => {
  return (
    <div
      id={id}
      className={classNames(
        `h-[543px] w-[670px] tile-shadow px-16 py-20 text-2xl leading-9 
      mb-16 transition-all duration-500`,
        !visibleOnScreen && 'translate-y-[500px] opacity-0'
      )}
    >
      {children}
    </div>
  );
};

const IndexPage = () => {
  const { t } = useTranslation();
  const [openOurCompanyDetail, setOpenOurCompanyDetail] = useState<
    string | undefined
  >();
  const [ourCompanyTiles, setOurCompanyTiles] = useState([
    {
      offsetFromSection: 100,
      id: 'it-consulting',
      visibleOnScreen: false,
      active: false,
    },
    {
      offsetFromSection: -450,
      id: 'e2e-project-delivery',
      visibleOnScreen: false,
      active: false,
    },
    {
      offsetFromSection: -1000,
      id: 'cloud-tech',
      visibleOnScreen: false,
      active: false,
    },
  ]);
  useEffect(() => {
    const section = document.getElementById('our-company');
    const scrollHandler = () => {
      const top = section?.getBoundingClientRect().top;
      setOurCompanyTiles((tiles) =>
        tiles.map((tile, i) => {
          const visibleOnScreen =
            tile.visibleOnScreen || top! <= tile.offsetFromSection;
          const tilesSorted = tiles.sort((a, b) =>
            a.offsetFromSection > b.offsetFromSection ? -1 : 1
          );
          return {
            ...tile,
            visibleOnScreen,
            active:
              last(tilesSorted.filter((t) => top! <= t.offsetFromSection)) ===
                tile ||
              (!i && top! > first(tiles)?.offsetFromSection!),
          };
        })
      );
    };
    window.addEventListener('scroll', scrollHandler);
    setTimeout(scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('index.page-title')}</title>
      </Helmet>
      <section className="text-white bg-cover relative" id="banner">
        <div
          className="h-[calc(100%+var(--full-menu-height))] w-full absolute -z-50 top-[calc(-1*var(--full-menu-height))]"
          style={{ backgroundImage: `url("${bgBannerLarge}")` }}
        />
        <CenteredContainer className="md:grid grid-cols-12 pt-[294px] md:pt-20 pb-16 md:pb-20">
          <div className="col-span-7 flex flex-col justify-center items-start">
            <header className="text-primary font-secondary font-black uppercase text-xs md:text-2xl mb-4 md:mb-8">
              {t('index.banner.text-1')}
            </header>
            <p className="font-secondary font-black md:uppercase text-[30px] md:text-6xl leading-tight mb-4 md:mb-11">
              {t('index.banner.text-2')}
            </p>
            <p className="md:text-[2.25rem] leading-snug line max-w-xl">
              {t('index.banner.text-3')}
            </p>
            <Link
              className="inline-block button-primary mt-8 md:mt-20"
              to="/#contact-us"
            >
              {t('shared.contact')}
            </Link>
          </div>
          <div className="col-span-5 absolute md:static -z-10 top-0 right-0 left-0 bottom-0 overflow-hidden h-full opacity-60 md:opacity-100">
            <img
              className="max-h-full md:max-h-[42rem] absolute md:static top-0 right-[-30px]"
              src={logoIcon}
              alt="Logo"
            />
          </div>
        </CenteredContainer>
      </section>
      <section className="padded-section" id="who-we-are">
        <NarrowCenteredContainer>
          <SectionHeader>{t('index.who-we-are.header')}</SectionHeader>
          <p
            className="font-secondary font-semibold text-header text-2xl md:text-5xl md:leading-[3.5rem] text-center mt-6"
            dangerouslySetInnerHTML={{ __html: t('index.who-we-are.text-1') }}
          />
          <div className="lg:grid grid-cols-10 gap-4 mt-8 md:mt-20">
            <div className="col-span-5 lg:w-fit lg:mx-auto">
              <img
                className="w-full max-w-[38.5rem] rounded-lg"
                src={whoWeAreImg}
                alt={t('index.who-we-are.header')}
              />
            </div>
            <div className="col-span-5">
              <p className="mt-8 md:mt-6 text-sm md:text-lg">
                {t('index.who-we-are.text-2')}
              </p>
              <ul className="md:grid grid-cols-2 gap-x-10 gap-y-6  mt-4 md:mt-8">
                {[...Array(4).keys()].map((key) => (
                  <li
                    className="before:font-icons before:content-['\e876'] before:align-[-10%] before:mr-2 before:text-primary text-sm md:text-lg font-semibold"
                    key={key}
                  >
                    {t(`index.who-we-are.bulletpoints.p${key + 1}`)}
                  </li>
                ))}
              </ul>
              <button className="button-primary text-primary active:text-white mt-8">
                {t('index.who-we-are.learn-more')}
              </button>
            </div>
          </div>
        </NarrowCenteredContainer>
      </section>
      <section
        className="padded-section"
        id="our-work-process"
        style={{ backgroundImage: `url("${bgGraingImg}")` }}
      >
        <NarrowCenteredContainer>
          <SectionHeader className="bg-[color:rgba(255,255,255,.4)]">
            {t('index.our-work-process.header')}
          </SectionHeader>
          <p className="text-center mt-5 md:mt-8 font-secondary font-semibold text-2xl md:text-5xl leading-tight text-header">
            {t('index.our-work-process.text-1')}
          </p>
          <p className="text-center mt-4 md:mt-6 max-w-5xl mx-auto text-sm md:text-2xl">
            {t('index.our-work-process.text-2')}
          </p>
          <div className="mt-8 md:mt-20 grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-4 xl:gap-x-24 xl:gap-y-16">
            <WorkProcessTextTile
              title={t('index.our-work-process.tiles.tile-1.title')}
              nb="01"
            >
              {t('index.our-work-process.tiles.tile-1.text')}
            </WorkProcessTextTile>
            <WorkProcessTextTile
              title={t('index.our-work-process.tiles.tile-2.title')}
              nb="02"
            >
              {t('index.our-work-process.tiles.tile-2.text')}
            </WorkProcessTextTile>
            <WorkProcessImgTile imgSrc={tile1Img} />
            <WorkProcessImgTile imgSrc={tile2Img} />
            <WorkProcessTextTile
              title={t('index.our-work-process.tiles.tile-3.title')}
              nb="03"
            >
              {t('index.our-work-process.tiles.tile-3.text')}
            </WorkProcessTextTile>
            <WorkProcessTextTile
              title={t('index.our-work-process.tiles.tile-4.title')}
              nb="04"
            >
              {t('index.our-work-process.tiles.tile-4.text')}
            </WorkProcessTextTile>
          </div>
        </NarrowCenteredContainer>
      </section>
      <section className="padded-section" id="our-company">
        <NarrowCenteredContainer>
          <SectionHeader>{t('index.our-company.header')}</SectionHeader>
          <p className="text-center mt-5 md:mt-8 font-secondary font-semibold text-2xl md:text-5xl !leading-tight text-header md:max-w-4xl ml-auto mr-auto">
            {t('index.our-company.text-1')}
          </p>
          <div className="hidden md:flex items-start gap-5 mt-20">
            <ol className="sticky top-56">
              <li>
                <OurCompanySubSectionHeader
                  isActive={
                    !!ourCompanyTiles.find((t) => t.id === 'it-consulting')
                      ?.active
                  }
                >
                  {t('index.our-company.items.item-1-title')}
                </OurCompanySubSectionHeader>
              </li>
              <li>
                <OurCompanySubSectionHeader
                  isActive={
                    !!ourCompanyTiles.find(
                      (t) => t.id === 'e2e-project-delivery'
                    )?.active
                  }
                >
                  {t('index.our-company.items.item-2-title')}
                </OurCompanySubSectionHeader>
              </li>
              <li>
                <OurCompanySubSectionHeader
                  isActive={
                    !!ourCompanyTiles.find((t) => t.id === 'cloud-tech')?.active
                  }
                >
                  {t('index.our-company.items.item-3-title')}
                </OurCompanySubSectionHeader>
              </li>
            </ol>
            <ol>
              <li>
                <OurCompanyTile
                  id="it-consulting"
                  visibleOnScreen={
                    !!ourCompanyTiles.find((t) => t.id === 'it-consulting')
                      ?.visibleOnScreen
                  }
                >
                  <Trans t={t} i18nKey="index.our-company.items.item-1-text" />
                </OurCompanyTile>
              </li>
              <li>
                <OurCompanyTile
                  id="e2e-project-delivery"
                  visibleOnScreen={
                    !!ourCompanyTiles.find(
                      (t) => t.id === 'e2e-project-delivery'
                    )?.visibleOnScreen
                  }
                >
                  <Trans t={t} i18nKey="index.our-company.items.item-2-text" />
                </OurCompanyTile>
              </li>
              <li>
                <OurCompanyTile
                  id="cloud-tech"
                  visibleOnScreen={
                    !!ourCompanyTiles.find((t) => t.id === 'cloud-tech')
                      ?.visibleOnScreen
                  }
                >
                  <Trans t={t} i18nKey="index.our-company.items.item-3-text" />
                </OurCompanyTile>
              </li>
            </ol>
          </div>
          <div className="md:hidden pt-4">
            {[
              ['item-1-title', 'item-1-text'],
              ['item-2-title', 'item-2-text'],
              ['item-3-title', 'item-3-text'],
            ].map(([titleKey, textKey]) => {
              const prefix = 'index.our-company.items';
              const title = t(`${prefix}.${titleKey}`);
              const fullTextKey = `${prefix}.${textKey}`;
              return (
                <details
                  className="custom-details border-b border-primary py-2 mt-4 group"
                  key={titleKey}
                  open={openOurCompanyDetail === titleKey}
                  onToggle={(event) =>
                    event.currentTarget.open &&
                    setOpenOurCompanyDetail(titleKey)
                  }
                >
                  <summary className="flex items-center justify-between font-semibold">
                    <span className="pl-2">{title}</span>
                    <span>
                      <svg
                        className="fill-current w-6 h-6 rotate-90 group-open:-rotate-90 transition-transform duration-300"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                      </svg>
                    </span>
                  </summary>
                  <div className="text-sm py-4">
                    <Trans t={t} i18nKey={fullTextKey} />
                  </div>
                </details>
              );
            })}
          </div>
        </NarrowCenteredContainer>
      </section>
      <section id="citations" className="text-white bg-secondary">
        <CenteredContainer>
          <div className="md:grid grid-cols-2 pt-12 md:pt-32 pb-6 md:pb-24">
            <p className="text-2xl md:text-5xl md:mr-28 font-secondary ">
              {t('index.citations.header')}
            </p>
            <p className="text-sm md:text-2xl mt-6 md:mt-0">
              {t('index.citations.text-1')}
            </p>
          </div>
        </CenteredContainer>
      </section>
      <section
        id="citations-quote"
        className="text-white bg-cover [background-position-x:54%]"
        style={{ backgroundImage: `url("${quoteImg}")` }}
      >
        <NarrowCenteredContainer className="pt-48 md:pt-36 pb-9 md:pb-64">
          <img
            className="max-w-[3rem] md:max-w-[8rem] rounded-lg"
            src={quoteIcon}
            aria-hidden="true"
          />
          <p className="mt-6 md:mt-7 font-secondary text-base md:text-4xl italic max-w-4xl">
            {t('index.citations.text-2')}
          </p>
        </NarrowCenteredContainer>
      </section>
      <section className="padded-section" id="contact-us">
        <NarrowCenteredContainer className="md:grid grid-cols-10 gap-x-12">
          <div className="col-span-6 overflow-hidden">
            <header className="font-secondary font-semibold text-primary text-2xl md:text-5xl">
              {t('index.contact.contact-us')}
            </header>
            <ContactForm className="mt-9 md:mt-28" mode="contact"></ContactForm>
          </div>
          <aside className="col-span-4 tile-shadow bg-secondary self-start pt-4 md:pt-16 pr-3 md:pr-11 pb-14 md:pb-11 pl-3 md:pl-16 rounded-lg text-white grid md:flex flex-col mt-14 md:mt-0">
            <p className="font-secondary font-semibold text-primary text-2xl md:text-5xl mb-4 md:mb-8">
              {t('index.contact.call-us')}
            </p>
            <a className="mb-2 md:mb-4" href="phone:+48 567 587 875">
              +48 567 587 875
            </a>
            <a className="mb-8 md:mb-14" href="phone:+48 567 587 875">
              +48 567 587 875
            </a>
            <p className="font-secondary font-semibold text-primary text-2xl md:text-5xl mb-4 md:mb-8 capitalize">
              {t('shared.e-mail')}
            </p>
            <a className="md:mb-16" href="mailto:awumba@awumba-us.com">
              awumba@awumba-us.com
            </a>
            <img
              className="self-end justify-self-end max-w-[105px] md:max-w-none md:w-1/2 row-start-1"
              src={logoCircleImg}
              alt="Logo"
            />
          </aside>
        </NarrowCenteredContainer>
      </section>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
